import { useSelector } from "react-redux";
import { getPhoto, getSessionId, getTableNumber, getName, updateCreateResult } from "../redux/slices";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Loading } from './Loading';
import { ProgressBar } from './ProgressBar';

import './playerEntryPhotoConfirm.css';
import { CreatePlayerDto, PlayerCreateResponse } from "../types/player";
import { useAppDispatch } from "../redux/hooks";

export const PlayerEntryPhotoConfirm = () => {
    const name = useSelector(getName);
    const photo = useSelector(getPhoto);
    const sessionId = useSelector(getSessionId);
    const tableNumber = useSelector(getTableNumber);
    const dispatch = useAppDispatch();
    const nav = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);    
    
    // BEGIN copied link checking
    
    const [searchParams] = useSearchParams();
    
    // if the redux store is missing a session ID (or the session ID does not match the URL parameter), 
    // then redirect to the start page. Suspect that this is a shared link and this "new" user needs to start at the beginning of the journey
    let sessionIdParam = searchParams.get('s');
    let tableNumberParam = searchParams.get('t');

    useEffect(() => {
        if (sessionId === null || tableNumber === null) {
            nav(`/?s=${sessionIdParam}&t=${tableNumberParam}`);
        }

        if (sessionId !== sessionIdParam || tableNumber !== tableNumberParam) {
            nav(`/?s=${sessionIdParam}&t=${tableNumberParam}`);
        }
        else {
            setLoading(false);
        }
    }, [sessionId, tableNumber, sessionIdParam, tableNumberParam, nav]);

    // END of "copied link" checking

    // send back to take another photo
    const retake = () => {
        nav(`/photo?s=${sessionId}&t=${tableNumber}`);
    };

    const submit = async () => {
        // wrap name a photo in the player DTO and send it to the server
        const playerDTO: CreatePlayerDto = {
            Name: name,
            Photo: photo
        }

        setLoading(true);

        // POST the created player DTO to the CreatePlayer endpoint
        try {
            const response: Response = await fetch(`/api/CreatePlayer/${sessionId}?t=${tableNumber}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(playerDTO)
            })

            if (response.ok) {
                const createResult: PlayerCreateResponse = await response.json();
                // console.log(createResult);
                dispatch(updateCreateResult(createResult));
            }
            else {
                dispatch(updateCreateResult({id: "", status: "Something went wrong"}));
            }
            setLoading(false);
            nav(`/details?s=${sessionId}&t=${tableNumber}`);

        } catch (error) {
            dispatch(updateCreateResult({id: "", status: "Something went wrong"}));
            setLoading(false);
            nav(`/details?s=${sessionId}&t=${tableNumber}`);
        }
        nav(`/details?s=${sessionId}&t=${tableNumber}`);
    };

    

    if (loading) return (<Loading />);

    return (        
        <div className="pageContainer">
            <div className="logoContainer">
                <img src={'./img/es-logo.svg'} width="90px" alt="Electric Shuffle" />
            </div>
            <ProgressBar progress={2} />
            <div className="playerPhotoContainer">
                <img className="playerPhotoMask" src={photo} alt="" />
                <div className="playerPhotoBorder"><img src="./img/photo_border.png" alt="" /></div>
            </div>
            <div className="playerNameBannerCenter">{name}</div>
            <footer className="btn-container">
                <button className="alt-button" disabled={photo === null} onClick={retake}>RE-TAKE</button>
                <button className="second-button" disabled={photo === null} onClick={submit}>ACCEPT</button>
            </footer>
        </div>)
}
